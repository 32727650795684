import React, { useContext, useEffect, useState } from 'react'
import { Row, Table, Modal, Button } from 'react-bootstrap'
import './PandingTabs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SampleCollectionModal from '../AllPathologyModal/SampleCollectionModal/SampleCollectionModal'
import AssignTestResultModal from '../AllPathologyModal/AssignTestResultModal/AssignTestResultModal'
import AssignTestResultEditModal from '../AllPathologyModal/AssignTestResultModal/AssignTestResultEditModal'
import PendingPreviewModal from '../AllPathologyModal/PendingPreviewModal/PendingPreviewModal'
import EditPendingModal from '../AllPathologyModal/SampleCollectionModal/EditPendingModal'
import { Context } from '../../../../../utils/context'
import { useParams } from 'react-router-dom'
import { Col, InputGroup, Nav, Tab } from 'react-bootstrap'
import Form from "react-bootstrap/Form";
import axios from 'axios';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PatientSuceesfullModal from '../../../VisitorDetails/VisitorCard/AllModalSec/PatientSuceesfullModal';


const PandingTabs = () => {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showTest, setShowTest] = useState(false);
    const [modalSample, setModalSample] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalPreview, setModalPreview] = useState(false);
    const [modalResult, setModalResult] = useState(false);
    const [modalResultEdit, setModalResultEdit] = useState(false);
    const [patient_name, setpatient_name] = useState("");
    const { getData, deleteData } = useContext(Context); // Assuming you have a context for API operations
    const [patientData, setPatientData] = useState([]); // State to store patient data
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [selectedPatientId, setSelectedPatientId] = useState(null);// State to store selected patient data
    const [selectedPatientTests, setSelectedPatientTests] = useState([]);
    const [patientname, setpatientname] = useState("");
    console.log(patientname, "patientname");
    const [modalSuccessPatient, setModalSuccessPatient] = useState(false);
    const getpatientData = async (page, patientname) => {
        try {
            let apiUrl;
            if (page || patientname) {
                apiUrl = `organisation/dashboard/apis/patient-details-get/?organisation_id=${id}&${page}`;
            } else {
                apiUrl = `organisation/dashboard/apis/patient-details-get/?organisation_id=${id}`;
            }
            const res = await getData(apiUrl);
            setPatientData(res?.results);
            setSelectedPage(res)
        } catch (error) {
            console.error('Error fetching patient data:', error);
        }
    };
    console.log(selectedPatientTests, "selectedPatientTests");
    useEffect(() => {
        window.scrollTo(0, 0);
        getpatientData();
    }, [id]);

    const toggleClick = (patient) => {
        setSelectedPatient(patient);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedPatient(null);
    };

    const toggleClickTest = (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patienty')
        setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
        setShowTest(true);
    };
    console.log(selectedPatient, "selectedPatient");

    const toggleClicksample = (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patienty sample')
        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
        setModalSample(true);
    };

    const toggleClickResult = (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patienty result')
        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
        setModalResult(true);
    };
    const toggleClickResultEdit = (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patienty result')
        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
        setModalResultEdit(true);
    };

    const toggleClickEdit = (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patienty result')
        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
        setModalEdit(true);
    };
    const toggleClickpreview = (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patienty result')
        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient
        setModalPreview(true);
       
    };

    const handleCloseTest = () => {
        setShowTest(false);
        setSelectedPatientTests(null);
    };
    const handleInputChange = (e) => {
        const newPatientName = e.target.value;
        setpatientname(newPatientName);
        getpatientData(" ", newPatientName);
    };

    const toggleClickdelete = async (patient) => {
        setSelectedPatient(patient);
        console.log(patient, 'patient result');

        try {
            // Call your delete API here
            const result = await deleteData(`organisation/dashboard/apis/patient/${patient.id}/`);

            if (result?.success) {
                setModalSuccessPatient(true);
                getpatientData();

            }
        } catch (error) {
            console.error('Error deleting patient record:', error);
            alert('An error occurred while deleting patient record');
        }

        // setSelectedPatientTests(patient?.patient_tests); // Assuming `tests` is the array of test data for the patient

    };

    //  const geteditData = async () => {
    //     try {
    //         const apiUrl = `organisation/dashboard/apis/test-get/?patient_id=${props?.patient?.id}`;
    //         const res = await getData(apiUrl);

    //         if (res?.success) {


    //             console.log('Patient data fetched successfully:', fetchedEditData);
    //         } else {
    //             console.error('No results found or invalid response format.');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching patient data:', error);
    //     }
    // };
    // useEffect(() => {
    //     geteditData();
    // }, [props?.patient?.id]);
    useEffect(() => {
        let timer;
        if (modalSuccessPatient) {
            timer = setTimeout(() => {
                setModalSuccessPatient(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [modalSuccessPatient]);
    console.log(selectedPage?.count, "patientData?.count kkkkkkkkkkpppppppppp");
    return (
        <>
            <Col lg={3} className='ms-auto'>
                <div className='patho-setting-tabs'>
                    <InputGroup className="mb-3 ">
                        <Form.Control
                            placeholder="Search"
                            aria-label="Username"
                            value={patientname}
                            aria-describedby="basic-addon1"
                            onChange={(e) => handleInputChange(e)}
                        />
                        <InputGroup.Text id="basic-addon1" >
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                        </InputGroup.Text>
                    </InputGroup>
                </div>
            </Col>
            <section className='panding-tabs'>
                <Row>
                    <div className='patient-table'>
                        <Table responsive >
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>ID</th>
                                    <th>Date</th>
                                    {/* <th>Time</th> */}
                                    <th>Patient</th>
                                    <th>Lab No.</th>
                                    <th>Source</th>
                                    <th>Test</th>
                                    <th>Sample</th>
                                    <th>Result</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {patientData?.map((patient, index) => (
                                    <tr key={patient.id}>
                                        <td>{index + 1}</td>
                                        <td>{patient?.id}</td>
                                        <td>{patient?.date}</td>
                                        {/* Uncomment this if 'time' is needed */}
                                        {/* <td>{patient?.time}</td> */}
                                        <td>
                                            {patient?.patient_name}
                                            <FontAwesomeIcon icon="fa-solid fa-eye" className='font-color ms-1' onClick={() => toggleClick(patient)} />
                                        </td>
                                        <td>{patient?.lab_no}</td>
                                        <td>{patient?.source?.name}</td>
                                        <td>
                                            <FontAwesomeIcon icon="fa-solid fa-eye" className='font-color ms-1' onClick={() => toggleClickTest(patient)} />
                                        </td>
                                        <td>
                                            {patient?.patient_sample_name && patient?.patient_sample_name?.review_status ? (
                                                patient?.patient_sample_name?.sample_status === '1' ? (
                                                    // <FontAwesomeIcon
                                                    //     icon="fa-solid fa-question"
                                                    //     className="font-color ms-1"
                                                    // />
                                                    // <FontAwesomeIcon icon="fa-solid fa-exclamation"
                                                    //     className="exclree ms-1" 
                                                    // />
                                                    <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" className="exclree ms-1"  />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-check"
                                                        className="font-color ms-1"
                                                    />
                                                )
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon="fa-solid fa-circle-plus"
                                                    className="font-color ms-1"
                                                    onClick={() => toggleClicksample(patient)}
                                                />
                                            )}
                                        </td>

                                        <td>
                                            {patient?.test_records?.main_status !== undefined ? (
                                                patient?.test_records?.main_status ? (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-check"
                                                        className='font-color ms-1'
                                                    // onClick={() => toggleClickResultEdit(patient)}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-circle-plus"
                                                        className='font-color ms-1'
                                                        onClick={() => toggleClickResultEdit(patient)}
                                                    />
                                                )
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon="fa-solid fa-circle-plus"
                                                    className='font-color ms-1'
                                                    onClick={() => toggleClickResult(patient)}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                className='btn btn-pending'
                                                onClick={() => {
                                                    if (patient?.patient_sample_name?.review_status && patient?.test_records?.main_status) {
                                                        toggleClickpreview(patient);
                                                    }
                                                }}
                                                disabled={patient?.patient_sample_name?.review_status && patient?.test_records?.main_status && patient?.status}
                                            >
                                                {patient?.patient_sample_name?.review_status && patient?.test_records?.main_status && patient?.status ? (
                                                    <>
                                                        <FontAwesomeIcon icon="fa-solid fa-circle" className='circle-color me-1' /> Final
                                                    </>
                                                ) : (
                                                    <>
                                                        <FontAwesomeIcon icon="fa-solid fa-circle" className='font-color ms-1' /> Pending/Prepared
                                                    </>
                                                )}
                                            </button>
                                        </td>
                                        <td>

                                            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" className='font-color me-2' onClick={() => toggleClickEdit(patient)} />
                                            <FontAwesomeIcon
                                                className="text"
                                                icon={faTrash}
                                                onClick={() => toggleClickdelete(patient)}
                                                style={{ cursor: 'pointer' }}
                                            />

                                        </td>

                                    </tr>
                                ))}
                            </tbody>

                        </Table>

                        {/* Pagination start */}
                        <div className="row me-0 ms-0">
                            <div class="col-md-12">
                                <div class="Pagination-holder">
                                    <ul class="pagination">
                                        {selectedPage?.previous && (
                                            <li class="page-item">
                                                <a class="page-link" role="button" tabIndex="0" onClick={() => getpatientData(selectedPage?.previous?.split("?")[1])}>
                                                    <span aria-hidden="true">‹</span>
                                                    <span class="visually-hidden">Previous</span>
                                                </a>
                                            </li>
                                        )}
                                        {[...Array(selectedPage?.count && Math.ceil(selectedPage?.count / 10))].map(function (_, index) {
                                            return (
                                                <li key={index} className="page-item">
                                                    <a className="page-link" role="button" tabIndex="0" onClick={() => getpatientData("page=" + (index + 1))}>
                                                        {index + 1}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                        {selectedPage?.next && (
                                            <li class="page-item">
                                                <a class="page-link" role="button" tabIndex="0" onClick={() => getpatientData(selectedPage?.next?.split("?")[1])}>
                                                    <span aria-hidden="true">›</span>
                                                    <span class="visually-hidden">Next</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Pagination end */}
                    </div>
                </Row>
            </section>
            <SampleCollectionModal
                HedaingName="Sample Collection"
                show={modalSample}
                patient={selectedPatient}
                getpatientData={getpatientData}
                onHide={() => setModalSample(false)}
            />
            <EditPendingModal
                HedaingName="Edit Data"
                SubHeading="Patient Details(1/2)"
                SecondText="Sample Collection(2/2)"
                show={modalEdit}
                patient={selectedPatient}
                getpatientData={getpatientData}
                onHide={() => setModalEdit(false)}
            />
            <AssignTestResultModal
                HedaingName="Assign Test Result"
                show={modalResult}
                patient={selectedPatient}
                getpatientData={getpatientData}
                onHide={() => setModalResult(false)}
            />
            <AssignTestResultEditModal
                HedaingName="Assign Test Edit Result"
                show={modalResultEdit}
                patient={selectedPatient}
                getpatientData={getpatientData}
                onHide={() => setModalResultEdit(false)}
            />
            <PendingPreviewModal
                HedaingName="Preview"
                SubHeading="Patient Details(1/2)"
                SecondText="Sample Collection(2/2)"
                show={modalPreview}
                patient={selectedPatient}
                getpatientData={getpatientData}
                onHide={() => setModalPreview(false)}
            />

            <Modal show={show} onHide={handleClose} centered className='patients-details'>
                <Modal.Header closeButton>
                    <Modal.Title >Patient Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedPatient && (
                        <div className="row box-details">
                            <div className='col-6'>
                                <p>Name:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.patient_name}</p>
                            </div>
                            <div className='col-6'>
                                <p>Number:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.phone_number}</p>
                            </div>
                            <div className='col-6'>
                                <p>Age:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.age_group}</p>
                            </div>
                            <div className='col-6'>
                                <p>Gender:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.gender}</p>
                            </div>
                            <div className='col-6'>
                                <p>Address:</p>
                            </div>
                            <div className='col-6'>
                                <p className='ms-3'>{selectedPatient.address}</p>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showTest} onHide={handleCloseTest} centered className='patients-details'>
                <Modal.Header closeButton>
                    <Modal.Title>Test Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedPatientTests && selectedPatientTests.length > 0 ? (
                        selectedPatientTests.map((t) => (
                            <div key={t?.id} className="row box-details">
                                <div className='col-6'>
                                    <p>Test Name:</p>
                                </div>
                                <div className='col-6'>
                                    <p className='ms-3'>{t?.test?.name}</p>
                                </div>
                                {/* <div className='col-6'>
                                    <p>Rate:</p>
                                </div>
                                <div className='col-6'>
                                    <p className='ms-3'>{test.rate}</p>
                                </div>
                                <div className='col-6'>
                                    <p>Unit:</p>
                                </div>
                                <div className='col-6'>
                                    <p className='ms-3'>{test.unit}</p>
                                </div> */}
                            </div>
                        ))
                    ) : (
                        <p>No tests available</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseTest}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <PatientSuceesfullModal
                textHeading="Patient Result Deleted successfully"
                show={modalSuccessPatient}
                onHide={() => setModalSuccessPatient(false)}
            />
        </>
    )
}

export default PandingTabs
