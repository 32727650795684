import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import {
  getData,
  postData,
  Select2Data,
  editPatchData,
  SelectUserData,
  // getEditData,
  // editData,
  editStatusData,
  getJobSeekerData,
  // postFormData,
  deleteData,
} from "./api";

export const Context = createContext();

const AppContext = ({ children }) => {
  const navigate = useNavigate();



  const [signin, setSignin] = useState(false);
  const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [orgType, setOrgType] = useState("");
  const [token, setToken] = useState("");
  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const isTokenValid = async () => {
    const token = Cookies.get("jwtToken");

    if (token) {
      setSignin(true);
      setUsertype(Cookies.get("jwtType"));
      setOrganisation(Cookies.get("jwtOrganisation"));
      if (Cookies.get("jwtOrganisation")) {
        const result = await getData(`organisation/dashboard/apis/single-org/?organisation_id=${Cookies.get("jwtOrganisation")}`);
        console.log(result?.data?.org_type, 'context');
        setOrgType(result?.data?.org_type);
      }


    } else {
      setSignin(false);
      setUsertype("");
      setOrganisation("");
      setOrgType("");
      navigate("/");
    }
  };

  const [ReactLoader, setReactLoader] = useState(true);
  const [sidebarChange, setSidebarChange] = useState(false);
  const [orgID, setOrgId] = useState("");
  const [orgData, setOrgData] = useState("");
  const [indexDetails, setIndexDetail] = useState("");




  // const imageUrl = "http://192.168.14.128:8001/";
  // const imageUrl = "http://192.168.16.44:8001/";
  const imageUrl = "http://35.154.223.32:8007/";
  // const imageUrl = "https://hiclousianode.profcyma.org:1212";
  //  const imageUrl = "https://admin.surjabasha.com";


  return (
    <Context.Provider
      value={{
        // minLength,
        // maxLength,
        getData,
        postData,
        // postFormData,
        // getEditData,
        // editData,
        editStatusData,
        editPatchData,
        deleteData,
        ReactLoader,
        setReactLoader,
        imageUrl,
        Select2Data,
        orgID,
        setOrgId,
        indexDetails,
        setIndexDetail,
        sidebarChange,
        setSidebarChange,
        orgData,
        setOrgData,
        signin,
        setSignin,
        usertype,
        setUsertype,
        organisation,
        setOrganisation,
        orgType,
        setOrgType,
        token,
        setToken,
        SelectUserData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
